//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: #{map-get($grays, "100")} !important;
  border-color    : #{map-get($grays, "100")} !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}

input.uppercase::placeholder {
  text-transform: none;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

// Clear Input
.clear-input {
  cursor: pointer;
  &:before {
    font-family   : "Material Design Icons";
    content       : "\F1398";
    font-style    : normal;
    font-weight   : 400;
    font-variant  : normal;
    vertical-align: middle;
    line-height   : 1.2;
    font-size     : 16px;
  }
}

// Show Password
.password-eye {
  cursor: pointer;
  &:before {
    font-family   : "Material Design Icons";
    content       : "\F06D0";
    font-style    : normal;
    font-weight   : 400;
    font-variant  : normal;
    vertical-align: middle;
    line-height   : 1.2;
    font-size     : 16px;
  }
}

.show-password {
  .password-eye {
    &:before {
      content: "\F06D1";
    }
  }
}

.rbt + .invalid-feedback {
  display: block;
}

.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;

  .rbt-close {
    margin-top: -0.25rem;
    pointer-events: auto;
    .rbt-close-content {
      color: transparent;
    }
  }

}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

// Dark mode
body[data-layout-color="dark"] {
  .form-control-light {
    background-color: #{map-get($dark-grays, "100")} !important;
    border-color    : #{map-get($dark-grays, "100")} !important;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}