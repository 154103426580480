//
// _calendar.scss
//

.fc:has(.fc-view-harness>.fc-customList-view) > .fc-header-toolbar {
  > .fc-toolbar-chunk:nth-of-type(1),
  > .fc-toolbar-chunk:nth-of-type(2) {
    visibility: hidden;
  }
} 

.fc-event {
  border-radius: 0.4rem;
}

.fc-customList-view {
  overflow-y: auto;
}

a.fc-event {
  cursor: pointer;
}
